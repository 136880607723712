@charset "utf-8";

$black: #231815;/* 黒 */
$gray: #333;/* グレー */
$light-gray: #b1b1b1;/* 薄いグレー */
$main: #003C9F;/* ブルー */
$sub: #FC4600;/* オレンジ */

$base-lh: 1.8;
$base-fs: 14px;
$reset-fs: 14;

$marugo: "ヒラギノ丸ゴ Pro W4","Hiragino Maru Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$mincho: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro","ＭＳ Ｐ明朝", "MS PMincho", serif;
$font-yu: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
$mincho-yu: "游明朝" , "Yu Mincho" , "游明朝体" , "YuMincho" , "ヒラギノ明朝 Pro W3" , "Hiragino Mincho Pro" , "HiraMinProN-W3" , "ＭＳ Ｐ明朝" , "MS PMincho" , serif;
$font-times: 'Times New Roman','Luchida Grande';
$font-josefin: 'Josefin Slab', serif;
$font-roboto: 'Roboto Condensed', sans-serif;