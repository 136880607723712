@charset "utf-8";

/* ローディングの背景部分のCSS */
.loader{
  background:#fff;
  height:100%;
  left:0;
  position:fixed;
  top:0;
  width:100%;
  z-index:10000;
}

/* ローディングのアニメーション部分のCSS (https://projects.lukehaas.me/css-loaders/) */
.loader-animation,
.loader-animation:before,
.loader-animation:after {
  background:$main;
  -webkit-animation:load1 1s infinite ease-in-out;
  animation:load1 1s infinite ease-in-out;
  width:1em;
  height:4em;
}
.loader-animation {
  height:10px;
  left:50%;
  margin:-5px 0 0 -5px;
  position:absolute;
  top:50%;
  width:10px;
  color:$main;
  text-indent:-9999em;
  font-size:10px;
  -webkit-transform:translateZ(0);
  -ms-transform:translateZ(0);
  transform:translateZ(0);
  -webkit-animation-delay:-0.16s;
  animation-delay:-0.16s;
}
.loader-animation:before,
.loader-animation:after {
  position:absolute;
  top:0;
  content:'';
}
.loader-animation:before {
  left:-1.5em;
  -webkit-animation-delay:-0.32s;
  animation-delay:-0.32s;
}
.loader-animation:after {
  left:1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow:0 0;
    height:4em;
  }
  40% {
    box-shadow:0 -2em;
    height:5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow:0 0;
    height:4em;
  }
  40% {
    box-shadow:0 -2em;
    height:5em;
  }
}

.loader-logo {
  position: relative;
  display: block;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 30px;
  border-bottom: 1px solid $sub;
  margin-left: -70px;
  margin-top: 70px;
  background: url(../img/common/logo.svg) no-repeat;
  background-size: 150px 20px;
}

.js-scroll-fade {
    position: relative;
    opacity: 0;
    transform: translate(0, 40px);
    transition: all 1s cubic-bezier(0.155, 0.910, 0.695, 0.950);
    &.is-animation {
        opacity: 1;
        transform: translate(0, 0);
    }
}