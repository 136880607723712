@charset "utf-8";

.contents {
  margin-bottom: 100px;
  &--default {
    @include base-wrap--space();
  }
}

.base-wrap {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
  &--space {
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
    @include mq(sp) {
      width: auto;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
.main-wrap {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
  padding-right: 100px;
  @include mq(sp) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
  &--space {
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 100px;
    padding-right: 100px;
    @include mq(sp) {
      width: auto;
      margin-left: 10px;
      margin-right: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
.screen-reader-text {
  display: none;
}
