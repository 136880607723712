@charset "utf-8";

/*--------------------------------------------------------
  スマホナビゲーション
----------------------------------------------------------*/

/* スマホグローバルナビ開閉ボタン */
.nav-sp {
  @include mq(sp) {
    display: none;
  }
}
.header__menu {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  @include mq(sp) {
    display: block;
    z-index: 1000;
  }
}
#panel-btn {
  display: inline-block;
  position: relative;
  width: 53px;
  height: 53px;
  background: $sub;
  text-decoration: none;
  text-align: center;
  @include box-sizing(border-box);
}
#panel-btn .close {
  background: transparent;
}
#panel-btn .close::before, #panel-btn .close::after {
  margin-top: 0;
  background: #fff;
}
#panel-btn .close::before {
  -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
}
#panel-btn .close::after {
  -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
}
#panel-btn-icon {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 2px;
  margin: -2px 0 0 -15px;
  background: #fff;
  -webkit-transition: .2s;
  transition: .2s;
}
#panel-btn-icon::before, #panel-btn-icon::after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 30px;
  height: 2px;
  background: #fff;
  -webkit-transition: .3s;
  transition: .3s;
}
#panel-btn-icon::before {
  margin-top: -12px;
}
#panel-btn-icon::after {
  margin-top: 10px;
}
#BtnClose {
  display: none;
  padding: 20px 0 20px;
  background: #efefef;
  color: $main;
  font-weight: bold;
  letter-spacing: .1em;
  text-align: center;
  @include fz_vw(20);
  @include mq(sp) {
    display: block;
  }
}

.nav-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  display: none;
  width: 100%;
  height: 100%;
  background: $black;
  opacity: 0;
}