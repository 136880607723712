@charset "utf-8";

/*--------------------------------------------------------
  タイトル
----------------------------------------------------------*/
/* 見出し（デフォルト下層h1用） */
.title-main {
  position: relative;
  display: inline-block;
  margin-bottom: 60px;
  padding-bottom: 30px;
  font-family: $font-josefin;
  font-size: 62px;
  font-weight: normal;
  line-height: 1.2;
  @include mq(sp) {
    @include fz_vw(36);
    padding-bottom: 15px;
  }
  &::before {
    position: absolute;
    top: -6px;
    right: -130px;
    content: "";
    display: block;
    width: 86px;
    height: 93px;
    background: url(../img/page/icn_title.png) no-repeat;
    @include mq(sp) {
      top: auto;
      bottom: -30px;
      right: 0;
      width: 50px;
      height: 54px;
      background-size: 50px 54px;
    }
  }
  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    content: "";
    display: block;
    width: 120px;
    height: 1px;
    margin-left: -60px;
    background: $sub;
  }
}

/* 下層ページの見出し（WYSIWYG用） */
.page {
  h2 {
    margin-bottom: 30px;
    font-family: $mincho;
    font-size: 30px;
    font-weight: normal;
    line-height: 1.3;
    @include mq(sp) {
      @include fz_vw(18);
    }
  }
  h3 {
    margin-bottom: 20px;
    border-bottom: 4px solid $sub;
    color: $main;
    font-size: 24px;
    @include mq(sp) {
      @include fz_vw(16);
    }
    a {
      text-decoration: none;
      color: $main;
      &:hover {
        text-decoration: underline;
        color: #888;
      }
    }
  }
  h4 {
    margin: 30px 0 20px;
    font-size: 18px;
    font-weight: bold;
  }
  h5 {
    margin-top: 20px;
    font-size: 18px;
  }
}

/* 見出し（カスタム下層用） */
.title-custom {
  position: relative;
  margin-top: 100px;
  margin-bottom: 60px;
  padding-bottom: 30px;
  font-family: $font-josefin;
  font-size: 62px;
  font-weight: normal;
  line-height: 1.2;
  text-align: center;
  @include mq(sp) {
    @include fz_vw(36);
    margin-top: 50px;
    padding-bottom: 15px;
  }
  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    content: "";
    display: block;
    width: 120px;
    height: 1px;
    margin-left: -60px;
    background: $sub;
  }
}
.sub-title-custom {
  margin-bottom: 20px;
  font-family: $mincho;
  font-size: 28px;
  @include mq(sp) {
    @include fz_vw(16);
  }
}

