@charset "utf-8";

/*--------------------------------------------------------
	サイドナビ
----------------------------------------------------------*/

/* ローカルナビ */
.widget-local-navi {
  & > ul {
    margin: 0 -2px;
    padding: 0 2px 15px;
    & > li {
      border-bottom: 1px dotted #ccc;
    }
  }
}

.widget-local-navi {
  li {
    &:last-child {
      border-bottom: none;
    }
    a {
      display: block;
      padding: 16px 10px 16px 16px;
      text-decoration: none;
      color: #333;
      &hover {
        color: #CC3300;
      }
    }
  }
}

/* ローカルナビ2層 */
.widget-local-navi ul ul {
  margin-left: 16px;
  margin-bottom: 10px;
}
.widget-local-navi ul ul li a {
  padding: 0px 10px 5px 20px;
  font-size: 13px;
}

/* ウィジェット共通 */
.widget {
  margin-bottom: 50px;
  h2 {
    margin-bottom: 20px;
    padding: 10px 15px;
    background:  $black;
    color: #fff;
    font-size: 15px;
  }
  img {
    max-width: 100%;
  }
  ul {
    li {
      position: relative;
      margin-left: 5px;
      margin-bottom: 10px;
      padding-left: 15px;
      &::before {
        position: absolute;
        top: 14px;
        left: 0;
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $black;
      }
      a {
        color: #333333;
        text-decoration: none;
      }
    }
  }
}

/* ブログウィジェット */
.blog-widget {
  li {
    padding: 10px 0;
    border-bottom: 1px dashed #ccc;
    line-height: 1.2;
    a {
      color: #1D4C86;
      text-decoration: none;
      &:hover {
        color: $sub;
        text-decoration: underline;
      }
    }
  }
}

/* ブログ カレンダー */
.widget-blog-calendar {
  table {
    border-collapse: collapse;
    box-sizing: border-box;
    th {
      padding: 6px 0;
      border: 1px solid $main;
      font-size: 12px;
    }
    td {
      padding: 6px 0;
      color: #aaa;
      text-align: center;
      border: 1px solid $main;
      font-size: 12px;
    }
    a {
      color: $sub;
      text-decoration: underline;
    }
    center {
      color: #000;
      font-size: 16px;
    }
  }
}