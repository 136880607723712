@charset "utf-8";

/*--------------------------------------------------------
  フォーム
----------------------------------------------------------*/

.form-table {
  tr {
    @include mq(sp) {
      margin-bottom: 1.5em;
    }
    th {
      width: 30%;
      padding: 20px 50px 20px 20px;
      @include mq(sp) {
        display: block;
        width: auto;
        padding: 5px 10px;
      }
    }
    td {
      @include mq(sp) {
        display: block;
        width: auto;
      }
    }
  }
  input[type=text] {
    @include box-sizing(border-box);
    width: 100%;
    margin: 0 10px 4px 0;
    padding: 8px;
    border: 1px solid #ccc;
    background: #FFE5E5;
    font-size: 14px;
  }
  .checkbox {
    font-size: 14px;
  }
  textarea {
    @include box-sizing(border-box);
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    background: #FFE5E5;
    font-size: 14px;
    @include mq(sp) {
    }
  }
  select {
    @include box-sizing(border-box);
    position: relative;
    margin: 0 0 4px 0;
    border: 1px solid #ccc;
    font-size: 14px;
  }
  a {
    color: $main;
    text-decoration: underline;
  }
  ul {
    li {
      padding-left: 0;
      &::before {
        display: none;
      }
    }
  }
}
.form-s {
  width: 24% !important;
}
.form-m {
  width: 36% !important;
}
.form-l {
  width: 90%;
  @include mq(sp) {
    width: 100%;
  }
}
.required {
  float: right;
  display: inline-block;
  padding: 1px 8px;
  background: $main;
  color: #fff;
  font-size: 12px;
}
.optional {
  float: right;
  display: inline-block;
  padding: 1px 8px;
  background: #999;
  color: #fff;
  font-size: 12px;
}
.submit {
  margin: 60px 0;
  text-align: center;
  @include mq(sp) {
    margin: 30px 0;
  }
  .form-submit {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    display: inline-block;
    margin: 10px 0;
    padding: 10px 100px;
    border: 1px solid #fff;
    background: $sub;
    color: #fff;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    @include mq(sp) {
      @include fz_vw(14);
      display: block;
      width: 100%;
      padding: 10px;
      text-align: center;
    }
    &::before {
      position: absolute;
      left: -28px;
      top: 50%;
      content: "";
      display: block;
      width: 57px;
      height: 1px;
      background: #fff;
      transition: .3s;
    }
    &::after {
      position: absolute;
      left: 23px;
      top: 50%;
      content: "";
      display: block;
      width: 9px;
      height: 1px;
      margin-top: -3px;
      background: #fff;
      transform: rotate(45deg);
      transition: .3s;
    }
    &:hover {
      @include mq(pc) {
        text-decoration: none;
        &::before {
          width: 120px;
          transition: .3s;
        }
        &::after {
          left: 86px;
          transition: .3s;
        }
      }
    }
  }
}
.mail-description {
  margin-bottom: 30px;
  @include mq(sp) {
    margin-bottom: 10px;
  }
  span {
    color: $main;
  }
}
.mail-before-attachment {
  margin-right: 10px;
}
.mail-after-attachment {
  margin-right: 10px;
}
#flashMessage {
  margin-bottom: 30px;
  @include mq(sp) {
    margin-bottom: 10px;
  }
}
.alert-message {
  color: #c00;
}
.form-back {
  margin-top: 50px;
  a {
  }
}
