@charset "utf-8";

/*--------------------------------------------------------
  ページ上部へ戻るボタン
----------------------------------------------------------*/
.page-top {
  background: #999999;
  text-align: center;
  cursor: pointer;
}
