@charset "utf-8";

/*--------------------------------------------------------
  フッター
----------------------------------------------------------*/
.footer {
    background: #333;
    color: #fff;
    a {
        color: #fff;
    }
    &__inner {
        @include base-wrap();
        @include flex();
        position: relative;
        padding: 60px 0;
        @include mq(sp) {
            padding: 30px 0 0;
        }
    }
    &__scissor {
        position: absolute;
        top: -27px;
        left: 20px;
        @include mq(sp) {
          top: -12px;
          width: 45px;
          height: 28px;
        }
    }
    &__info {
        width: 340px;
        @include mq(sp) {
            width: auto;
            margin-bottom: 30px;
            text-align: center;
        }
        &__name {
            @include fs(16);
            margin-bottom: 8px;
        }
        &__address {
            @include fs(13);
            color: #B1B1B1;
        }
        &__pass {
            color: #B1B1B1;
            @include fs(13);
        }
    }
}
.fnavi {
    @include fs(15);
    width: 400px;
    padding-left: 40px;
    border-left: 1px solid #686868;
    @include mq(sp) {
        display: none;
        width: auto;
        margin-bottom: 30px;
        padding-left: 30px;
        border-left: none;
    }
    a {
        position: relative;
        display: inline-block;
        &::after {
            position: absolute;
            left: 0;
            bottom: 0;
            content: "";
            display: block;
            width: 0;
            height: 1px;
            background: #fff;
            transition: .5s;
        }
        &:hover::after {
            @include mq(pc) {
                width: 100%;
            }
        }
    }
}
.copyright {
    @include fs(12);
    padding-top: 60px;
    color: #B1B1B1;
    @include mq(sp) {
        padding: 20px 0;
        background: #fff;
        text-align: center;
    }
}
/* ページ上へ戻る */
#TopLink {
    position: fixed;
    bottom: 50px;
    left: 50%;
    z-index: 10000;
    width: 70px;
    height: 70px;
    margin-left: 480px;
    border-radius: 50%;
    background: #666;
    text-align: right;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.8;
    @include mq(sp) {
        display: none;
    }
    &::before {
        position: absolute;
        top: 24px;
        left: 50%;
        content: "";
        display: block;
        width: 1px;
        height: 24px;
        margin-left: -1px;
        background: #fff;
    }
    &::after {
        position: absolute;
        top: 22px;
        left: 50%;
        content: "";
        display: block;
        width: 14px;
        height: 14px;
        margin-left: -8px;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        transform: rotate(-45deg);
    }
    a {
        display: none;
    }
}

.bnavi {
    display: none;
    @include mq(sp) {
        display: block;
    }
    &__list {
        &__item {
            a {
                display: block;
                padding: 10px 0;
                text-align: center;
            }
            &:nth-child(odd) {
                background: #EBEBEB;
            }
        }
    }
}