@charset "utf-8";

/*--------------------------------------------------------
	ボタン
----------------------------------------------------------*/

.button {
    position: relative;
    display: inline-block;
    margin: 10px 0;
    padding: 10px 70px;
    border: 1px solid #fff;
    color: #fff;
    text-align: center;
    text-decoration: none;
    @include mq(sp) {
        @include fz_vw(14);
        padding: 10px 70px;
    }
    &::before {
        position: absolute;
        left: -28px;
        top: 50%;
        content: "";
        display: block;
        width: 57px;
        height: 1px;
        background: #fff;
        transition: .3s;
    }
    &::after {
        position: absolute;
        left: 23px;
        top: 50%;
        content: "";
        display: block;
        width: 9px;
        height: 1px;
        margin-top: -3px;
        background: #fff;
        transform: rotate(45deg);
        transition: .3s;
    }
    &:hover {
        @include mq(pc) {
            text-decoration: none;
            &::before {
              width: 80px;
              transition: .3s;
            }
            &::after {
              left: 46px;
              transition: .3s;
            }
        }
    }
    &--main {
        background-color: $main;
        font-size: 18px;
    }
}

.btn-wrap {
    text-align: center;
    a {
        display: inline-block;
    }
}