@charset "utf-8";


@mixin opacity($opacity) {
	opacity: $opacity;
	filter: alpha(opacity=($opacity * 100));
}

@mixin clearfix() {
	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}

@mixin box-sizing($type) {
	-webkit-box-sizing:$type;
	-moz-box-sizing:$type;
	box-sizing:$type;
}

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}

@mixin fadeover {
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

@mixin base-wrap {
	width: 1000px;
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}
@mixin base-wrap--space {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}
@mixin main-wrap {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
  padding-right: 100px;
  @include mq(sp) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
@mixin main-wrap--space {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
  padding-right: 100px;
  @include mq(sp) {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@mixin separate {
	position: relative;
	&::before {
		position: absolute;
		bottom: 0;
		left: 50%;
		content: "";
		display: block;
		width: 90px;
		height: 1px;
		margin-left: -95px;
		background: $orange;
	}
	&::after {
		position: absolute;
		bottom: 0;
		left: 50%;
		content: "";
		display: block;
		width: 90px;
		height: 1px;
		margin-left: 5px;
		background: $orange;
	}
}
@mixin arrow {
  position: relative;
  &::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    content: "";
    display: block;
    width: 50px;
    height: 50px;
    margin-left: -25px;
    border-bottom: 1px solid $orange;
    border-right: 1px solid $orange;
    transform: rotate(45deg);
    @include mq(sp) {
      width: 30px;
      height: 30px;
      margin-left: -15px;
    }
  }
}

@mixin bg_s {
  -webkit-background-size: 5px 5px !important;
  -moz-background-size: 5px 5px !important;
  background-size: 5px 5px !important;
  background-color: #F1F1F1 !important;
  background-image: -webkit-gradient(linear, 0 0, 100% 100%,
              color-stop(.25, #CECECE), color-stop(.25, transparent),
              color-stop(.5, transparent), color-stop(.5, #CECECE),
              color-stop(.75, #CECECE), color-stop(.75, transparent),
              to(transparent)) !important;
  background-image: -webkit-linear-gradient(-45deg, #CECECE 25%, transparent 25%,
            transparent 50%, #CECECE 50%, #CECECE 75%,
            transparent 75%, transparent) !important;
  background-image: -moz-linear-gradient(-45deg, #CECECE 25%, transparent 25%,
            transparent 50%, #CECECE 50%, #CECECE 75%,
            transparent 75%, transparent) !important;
  background-image: -ms-linear-gradient(-45deg, #CECECE 25%, transparent 25%,
            transparent 50%, #CECECE 50%, #CECECE 75%,
            transparent 75%, transparent) !important;
  background-image: -o-linear-gradient(-45deg, #CECECE 25%, transparent 25%,
            transparent 50%, #CECECE 50%, #CECECE 75%,
            transparent 75%, transparent) !important !important;
  background-image: linear-gradient(-45deg, #CECECE 25%, transparent 25%,
            transparent 50%, #CECECE 50%, #CECECE 75%,
            transparent 75%, transparent) !important;
}
@mixin bg_02 {
  -webkit-background-size: 10px 10px;
  -moz-background-size: 10px 10px;
  background-size: 10px 10px;
  background-color: #666666;
  background-image: -webkit-gradient(linear, 0 0, 100% 100%,
              color-stop(.25, #606060), color-stop(.25, transparent),
              color-stop(.5, transparent), color-stop(.5, #606060),
              color-stop(.75, #606060), color-stop(.75, transparent),
              to(transparent));
  background-image: -webkit-linear-gradient(-45deg, #606060 25%, transparent 25%,
            transparent 50%, #606060 50%, #606060 75%,
            transparent 75%, transparent);
  background-image: -moz-linear-gradient(-45deg, #606060 25%, transparent 25%,
            transparent 50%, #606060 50%, #606060 75%,
            transparent 75%, transparent);
  background-image: -ms-linear-gradient(-45deg, #606060 25%, transparent 25%,
            transparent 50%, #606060 50%, #606060 75%,
            transparent 75%, transparent);
  background-image: -o-linear-gradient(-45deg, #606060 25%, transparent 25%,
            transparent 50%, #606060 50%, #606060 75%,
            transparent 75%, transparent);
  background-image: linear-gradient(-45deg, #606060 25%, transparent 25%,
            transparent 50%, #606060 50%, #606060 75%,
            transparent 75%, transparent);
}