@charset "utf-8";

/*--------------------------------------------------------
  STORY
----------------------------------------------------------*/
.story-about, .story-buy, .story-rental {
  .title-custom {
    font-size: 32px;
    @include mq(sp) {
      @include fz_vw(26);
    }
  }
}
.story-about {
  @include main-wrap--space();
  margin-top: 100px;
  margin-bottom: 60px;
  &__logo {
    width: 320px;
    margin: 0 auto 30px;
    @include mq(sp) {
      width: 60%;
    }
  }
  &__body {
    margin-bottom: 40px;
    p {
      margin-bottom: 20px;
      a {
        color: $main;
        text-decoration: underline;
      }
    }
  }
}
.story-history {
  @include main-wrap--space();
  margin-bottom: 100px;
}
.story-record {
  @include main-wrap--space();
  margin-bottom: 100px;
  &__text {
    margin-bottom: 30px;
  }
}
.page-sv {
  @include flex();
  margin-bottom: 100px;
  &__box {
    position: relative;
    width: 360px;
    background: #000;
    color: #fff;
    @include mq(sp) {
      width: 300px;
      text-align: left;
    }
    &::after {
      position: absolute;
      bottom: -100px;
      left: 0;
      display: block;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      @include mq(sp) {
        bottom: -60px;
      }
    }
  }
  &__title {
    position: relative;
    display: inline-block;
    margin-bottom: 40px;
    padding-bottom: 20px;
    font-family: $font-josefin;
    font-size: 62px;
    font-weight: normal;
    line-height: 1.2;
    @include mq(sp) {
      @include fz_vw(36);
      padding-bottom: 15px;
    }
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      display: block;
      width: 120px;
      height: 1px;
      background: $sub;
    }
  }
  &__image {
    width: calc(100vw - 360px);
    @include mq(sp) {
      width: auto;
    }
    img {
      width: 100%;
    }
  }
  &__text {
    font-family: $mincho;
    font-size: 26px;
    line-height: 1.5;
    @include mq(sp) {
      font-size: fz_vw(20);
    }
    span {
      font-size: 36px;
      @include mq(sp) {
        font-size: fz_vw(24);
      }
    }
  }
  &--left {
    flex-direction: row-reverse;
    .page-sv__box {
      padding: 140px 0 0 60px;
      @include mq(sp) {
        position: relative;
        top: -60px;
        padding: 50px 0 50px 50px;
      }
      &::after {
        border-width: 100px 360px 0 0;
        border-color: rgba(0,0,0,0.4) transparent transparent transparent;
        @include mq(sp) {
          border-width: 60px 300px 0 0;
        }
      }
    }
  }
  &--right {
    @include mq(sp) {
      text-align: right;
    }
    .page-sv__image {
      position: relative;
      z-index: 20;
    }
    .page-sv__box {
      padding: 80px 0 0 60px;
      position: relative;
      z-index: 50;
      @include mq(sp) {
        position: relative;
        top: -60px;
        display: inline-block;
        padding: 50px 0 50px 50px;
      }
      &::after {
        border-width: 0 360px 100px 0;
        border-color: transparent rgba(0,0,0,0.4) transparent transparent;
        @include mq(sp) {
          border-width: 0 300px 60px 0;
        }
      }
    }
  }
}
.story-list {
  margin-bottom: 100px;
  &__body {
    @include main-wrap--space();
    p {
      margin-bottom: 20px;
    }
    ol {
      margin-left: 20px;
      li {
        margin-bottom: 10px;
      }
    }
  }
}
