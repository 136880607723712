@charset "utf-8";

/*--------------------------------------------------------
  メインイメージ
----------------------------------------------------------*/

.mainimage {
  position: relative;
  overflow: hidden;
  height: 100%;
  video {
    min-width: 100%;
    height: 100%;
    min-height: 800px;
    vertical-align: top;
    @include mq(sp) {
      position: relative;
      left: -330px;
      min-width: auto;
      min-height: auto;
      width: auto;
      height: 100vh;
    }
  }
  &__copy {
    position: absolute;
    top: 350px;
    left: 0;
    z-index: 100;
    width: 100%;
    color: #fff;
    font-size: 36px;
    font-weight: bold;
    line-height: 1.4;
    text-align: center;
    text-align: center;
    text-shadow: 1px 1px 1px $black;
    opacity: 0;
    z-index: 10;
    @include mq(sp) {
      @include fz_vw(18);
      top: 40vh;
      right: 20px;
      padding: 10px 0 10px 20px;
      margin-top: 0;
    }
  }
  img {
    width: 100%;
    vertical-align: bottom;
  }
}

@keyframes maincopy {
  0% {
    opacity: 0;
    transform: transrateY(0);
  }
  5% {
    opacity: 1;
    transform: translateY(-70px);
  }
  23% {
    opacity: 1;
    transform: translateY(-70px);
  }
  25% {
    opacity: 0;
  }
}
.mainimage__copy {
  -moz-animation:maincopy 20s infinite;
  -webkit-animation:maincopy 20s infinite;
  animation:maincopy 20s infinite;
  &--01 {
    animation-delay: 0s
  }
  &--02 {
    animation-delay: 5s
  }
  &--03 {
    animation-delay: 10s
  }
  &--04 {
    animation-delay: 15s
  }
}

.mv {
  height: 90vh;
  @include mq(sp) {
    height: 90vh;
  }
  &-content {
    position: absolute;
    top: 0;
    left: 0;
  }
}