@charset "utf-8";
@include mq(pc) {
  .delighter.anime-left {
    transition: all .3s ease-out;
    transform: translateX(-5%);
    opacity: 0;
    &.started {
      transform: none;
      opacity: 1;
      &.ended {
      }
    }
  }
  .delighter.anime-right {
    transition: all .3s ease-out;
    transform: translateX(5%);
    opacity: 0;
    &.started {
      transform: none;
      opacity: 1;
      &.ended {
      }
    }
  }
  .delighter.anime01 {
    transition: all .6s ease-out;
    transform: translateY(10%);
    opacity: 0;
    &.started {
      transform: none;
      opacity: 1;
    }
  }
  .delighter.anime02 {
    transition: all 1s ease-out;
    transform: translateY(20%);
    &.started {
      transform: none;
      &.ended {
      }
    }
  }
  .delighter.anime03 {
    transition: all .6s ease-out;
    transform: translateY(30%);
    opacity: 0;
    &.started {
      transform: none;
      opacity: 1;
    }
  }
  .delighter.top-index__scissor {
    transition: all .5s ease-out;
    transform: none;
    &.started {
      transform: rotate(-30deg);
      &.ended {
        transform: rotate(710deg);
      }
    }
  }
}