@charset "utf-8";

html {
  width: 100%;
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
	margin: 0;
	padding: 0;
  padding-top: 84px;
	/*font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;*/
  font-family: $font-yu;
	font-style: normal;
	font-weight: normal;
	color: $black;
	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
	font-size: $base-fs;
  line-height: $base-lh;
  overflow-x: hidden;
  @include mq(tab) {
    width: 1024px;
  }
  @include mq(pc) {
    min-width: 1024px;
  }
  @include mq(sp) {
    padding-top: 54px;
  }
}

a {
	color: $black;
  text-decoration: none;
  transition: .2s;
  &:hover {
    transition: .2s;
  }
  &:visited {
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

a:hover img {
    opacity: 0.9;
}